<template>
    <div class="layout-header" :class="scroll ? 'scroll' : ''">
        <router-link class="logo" to="/">
            <img :src="logo" />
        </router-link>
        <ul class="header-navbar">
            <li>
                <router-link class="t" to="/">
                    <span class="text">HOME</span>
                </router-link>
            </li>
            <li v-for="(item, index) in menus" :key="index">
                <router-link class="t" :to="`/${item.type}/${item.id}`">
                    <span class="text">{{ item.title }}</span>
                    <down v-if="item.children && item.children.length > 0" class="icon" theme="outline" size="22" fill="rgba(255,255,255, .5)"/>
                </router-link>
                <ul class="sun" v-if="item.children && item.children.length > 0">
                    <li v-for="(child, cIndex) in item.children" :key="cIndex">
                        <router-link class="s" :to="`/${child.type}/${child.id}`">
                            <span class="text">{{ child.title }}</span>
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { Down } from '@icon-park/vue-next'
export default {
    name: 'NHeader',
    components: {
        Down
    },
    props: {
        scroll: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            menus: [],
            logo: ''
        }
    },
    mounted(){
        this.getMenu();
        this.getSetting();
    },
    methods:{
        getMenu(){
            this.$api.menus().then(res => {
                this.menus = res.data;
            })
        },
        getSetting(){
            this.$api.homeSetting().then(res => {
                if(res.msg === 'success'){
                    this.logo = res.data.logo;
                    this.$route.meta.content.keywords = res.data.keywords;
                    this.$route.meta.content.description = res.data.description;
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.layout-header{
    @extend .flex-row-between;
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0 30px;
    .logo img{
        height: 84px;
    }
    .header-navbar{
        @extend .flex-row;
        height: 84px;
        li{
            @extend .flex-column-middle;
            position: relative;
            list-style: none;
            a{
                @extend .flex-row;
                color: $white;
                &.t{
                    padding: 0 15px;
                    span{
                        @extend .flex-column-middle;
                        &.icon{
                            transform: rotate(0);
                        }
                    }
                }
            }
            &:hover .t .icon{
                transform: rotate(180deg);
            }
            .sun{
                display: none;
                position: absolute;
                top: 80px;
                padding: 5px 0;
                border-radius: $radius;
                background-color: rgba(255,255,255,.9);
                a{
                    padding: 10px 15px;
                    white-space: nowrap;
                    color: $text-color;
                    font-size: 13px;
                    &:hover{
                        color: $orange;
                    }
                }
            }
            &:hover .sun{
                display: block;
            }
        }
    }
    &.scroll{
        background-color: rgba(0,0,0,.5);
        .logo{
            img { 
                height: 60px;
            }
        }
        .header-navbar{
            height: 60px;
            li{
                .sun{
                    top: 60px;
                }
            }
        }
    }
}
</style>