import {http} from '@/api/interceptor.js';

function menus(){
    return http({
        url: '/getmenus'
    })
}

function homeCarousels(){
    return http({
        url: '/home/carousels'
    })
}

function homeEquipmentCat(num){
    return http({
        url: `/home/equipmentCat/${num}`
    })
}

function homeEquipments(cat_id){
    return http({
        url: `/home/equipments/${cat_id}`
    })
}

function homeAbout(){
    return http({
        url: '/home/about'
    })
}

function homeService(){
    return http({
        url: '/home/service'
    })
}

function homeContact(){
    return http({
        url: '/home/contact'
    })
}

function homeMessage(data){
    return http({
        url: '/home/message',
        method: 'POST',
        data: data
    })
}

function homeStories(){
    return http({
        url: '/home/stories'
    })
}
function homeCases(){
    return http({
        url: '/home/cases'
    })
}
function homeSetting(){
    return http({
        url: '/home/setting'
    })
}

function childrenCat(id){
    return http({
        url: `/equipments/childrenCat/${id}`
    })
}
function equipments(cat_id){
    return http({
        url: `/equipments/${cat_id}`
    })
}
function equipmentsByCat(cat_id){
    return http({
        url: `/equipments/list/${cat_id}`
    })
}
function equipmentShow(id){
    return http({
        url: `/equipments/show/${id}`
    })
}
function hotEquipments(){
    return http({
        url: '/equipments/hot'
    })
}

function articles(cat_id){
    return http({
        url: `/articles/${cat_id}`
    })
}
function showArticle(id){
    return http({
        url: `/articles/show/${id}`
    })
}
function category(cat_id){
    return http({
        url: `/articles/category/${cat_id}`
    })
}

function page(cat_id){
    return http({
        url: `/pages/${cat_id}`
    })
}

function getNextPage(url){
    return http({
        url: url
    })
}

export default {
    menus,
    homeCarousels,
    homeEquipmentCat,
    homeEquipments,
    homeAbout,
    homeService,
    homeContact,
    homeMessage,
    homeStories,
    homeCases,
    homeSetting,

    childrenCat,
    equipments,
    equipmentsByCat,
    equipmentShow,
    hotEquipments,

    articles,
    showArticle,
    category,
    page,

    getNextPage
}