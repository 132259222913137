<template>
    <div class="layout-footer">
    
        <div class="layout-bottom">
            <div class="container">
                <div class="product-links">
                    <h4>Products</h4>
                    <template v-for="(item, index) in productCats" :key="index">
                        <router-link :to="`/equipment/${item.id}`">{{ item.title }}</router-link>
                    </template>
                </div>
                <div class="contact-box">
                    <h4>Contact</h4>
                    <div class="list">
                        <div class="item" v-if="contact.phone">
                            <phone theme="outline" size="20" fill="#999"/>
                            <span class="grey">+0086</span>
                            <span class="text">{{ contact.phone }}</span>
                        </div>
                        <div class="item" v-if="contact.tel">
                            <phone-telephone theme="outline" size="20" fill="#999"/>
                            <span class="grey">+0086</span>
                            <span class="text">{{ contact.tel }}</span>
                        </div>
                        <div class="item" v-if="contact.email">
                            <mail theme="outline" size="20" fill="#999"/>
                            <span class="text">{{ contact.email }}</span>
                        </div>
                        <div class="item" v-if="contact.url">
                            <browser-chrome theme="outline" size="20" fill="#999"/>
                            <span class="text">{{ contact.url }}</span>
                        </div>
                        <div class="item" v-if="contact.location">
                            <local theme="outline" size="20" fill="#999"/>
                            <span class="text local">{{ contact.location }}</span>
                        </div>
                    </div>
                    <div class="media-main">
                        <a v-if="contact.facebook" :href="contact.facebook">
                            <facebook theme="filled" size="24" fill="#fff" :strokeWidth="1"/>
                        </a>
                        <a v-if="contact.youtube" :href="contact.youtube">
                            <youtube theme="multi-color" size="24" :fill="['#fff' ,'#fff' ,'#FFF' ,'#222']" :strokeWidth="1"/>
                        </a>
                        <a v-if="contact.twitter" :href="contact.twitter">
                            <twitter theme="filled" size="24" fill="#fff" :strokeWidth="2"/>
                        </a>
                        <a v-if="contact.instagram" :href="contact.instagram">
                            <instagram theme="multi-color" size="24" :fill="['#fff' ,'#fff' ,'#222' ,'#fff']" :strokeWidth="3"/>
                        </a>
                        <a v-if="contact.email" :href="`mailto:${contact.email}`">
                            <mail theme="multi-color" size="24" :fill="['#fff' ,'#fff' ,'#222' ,'#fff']"/>
                        </a>
                    </div>
                </div>
                <div class="message-box">
                    <h4>Get A Price</h4>
                    <div class="msg-form">
                        <div class="item">
                            <input class="input" placeholder="Your Name" v-model="form.name" />
                        </div>
                        <div class="item doubel">
                            <input class="input" 
                                :class="formMsg.phone !== '' ? 'error' : ''"
                                placeholder="Your Phone" v-model="form.phone" 
                            />
                            <input class="input" 
                                :class="formMsg.email !== '' ? 'error' : ''"
                                placeholder="Your Email" type="email" v-model="form.email" 
                            />
                        </div>
                        <div class="item" v-if="formMsg.phone !== '' || formMsg.email !== ''">
                            <p class="error">
                                <span v-if="formMsg.phone !== ''">{{ formMsg.phone }}</span>
                                <span v-if="formMsg.email !== ''">, {{ formMsg.email }}</span>
                            </p>
                        </div>
                        <div class="item">
                            <textarea 
                                class="input textarea" 
                                :class="form.content === '' ? 'error' : ''"
                                placeholder="Your Message" v-model="form.content"
                            />
                        </div>
                        <div class="item" v-if="form.content === ''">
                            <p class="error">Message can not be null</p>
                        </div>
                        <div class="item captcha">
                            <input class="input" 
                                :class="formMsg.code !== '' ? 'error' : ''"
                                placeholder="Input verification code" v-model="form.code" 
                            />
                            <n-captcha 
                                :identifyCode="form.identifyCode" 
                                @click="refreshCode" 
                            />
                        </div>
                        <div class="item" v-if="formMsg.code !== ''">
                            <p class="error">{{ formMsg.code }}</p>
                        </div>
                        <n-button class="btn"
                            :disabled="form.name === '' || form.phone === '' || form.email === '' || form.message === '' || formMsg.phone !== '' || formMsg.email !== '' || formMsg.code !== ''"
                            @click="submitMsg"
                        >Send Message</n-button>
                    </div>
                    <div class="mask-sended" v-if="msgStatus.sended">Message is sented</div>
                </div>
            </div>
        </div>

        <div class="footer-box">
            COPYRIGHT © 2020 Zhengzhou Xuanrun Machinery Equipment CO. LTD. All Rights Reserved.
        </div>

        <div class="modal-status" v-if="msgStatus.show" @click="msgStatus.show = false">
            <div class="modal-container">
                <close class="icon" theme="outline" size="18" fill="#333" @click="msgStatus.show = false"/>
                <h2>Message sent successfully</h2>
            </div>
        </div>

    </div>
</template>

<script>
import { 
    Phone, PhoneTelephone, Mail, 
    BrowserChrome, Local, 
    Facebook, Youtube, Twitter,
    Instagram, Close
} from '@icon-park/vue-next'
import NCaptcha from '@/components/Captcha.vue'
import NButton from '@/components/Button.vue'
export default {
    name: 'NFooter',
    components: {
        Phone, PhoneTelephone, Mail, 
        BrowserChrome, Local, 
        Facebook, Youtube, Twitter,
        Instagram, Close,
        NCaptcha, NButton
    },
    data(){
        return {
            msgStatus: {
                sended: false,
                show: false
            },
            form: {
                name: '',
                phone: '',
                email: '',
                content: '',
                code: '',
                identifyCodes: '1234567890',
                identifyCode: '',
                btnLoading: false
            },
            productCats: [],
            contact: {},
            formMsg: {
                phone: '',
                email: '',
                code: ''
            }
        }
    },
    watch: {
        'form.phone'(){
            let num = /^[0-9]*$/;
            if(this.form.phone !== '' && !num.test(this.form.phone)) this.formMsg.phone = 'Error Phone';
            else this.formMsg.phone = '';
        },
        'form.email'(){
            let regEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            if(this.form.email !== '' && !regEmail.test(this.form.email)) this.formMsg.email = 'Error Email';
            else this.formMsg.email = '';
        },
        'form.code'(){
            if(this.form.code !== this.form.identifyCode) this.formMsg.code = 'Error Code';
            else this.formMsg.code = '';
        }
    },
    mounted(){
        this.form.identifyCode = "";
        this.makeCode(this.form.identifyCodes, 4);
        this.getProducts();
        this.getContact();
    },
    methods: {
        // 生成一个随机数
        randomNum(min, max) {
            return Math.floor(Math.random() * (max - min) + min)
        },
        refreshCode() {
            this.form.identifyCode = "";
            this.makeCode(this.form.identifyCodes, 4);
        },
        makeCode(o, l) {
            for (let i = 0; i < l; i++) {
                this.form.identifyCode += this.form.identifyCodes[
                    this.randomNum(0, this.form.identifyCodes.length)
                ];
            }
        },
        getProducts(){
            this.$api.homeEquipmentCat().then(res => {
				this.productCats = res.data;
			})
        },
        getContact(){
            this.$api.homeContact().then(res => {
                this.contact = res.data;
            })
        },
        submitMsg(){
            this.btnLoading = true;
            this.$api.homeMessage(this.form).then(res => {
                if(res.msg === 'success') {
                    this.msgStatus.sended = true;
                    this.msgStatus.show = true;
                    this.btnLoading = false;
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.layout-footer{
    @extend .flex-column;
    z-index: 999;
    position: relative;
    .container{
        width: 1200px;
        margin: 0 auto;
    }
    .layout-bottom{
        padding: 30px 0;
        background-color: $white;
        h4{
            font-family: 'cusTitle';
            margin: 0 0 15px;
            font-size: 22px;
            color: $orange;
        }
        .container{
            @extend .flex-row;
        }

        .product-links{
            @extend .flex-column;
            width: 30%;
            a{
                margin: 10px 0;
                color: $text-color;
                &:hover{
                    color: $orange;
                }
            }
        }
        .contact-box{
            @extend .flex-column;
            width: 25%;
            .list{
                .item{
                    @extend .flex-row;
                    margin: 13px 0;
                    .grey{
                        margin-left: 10px;
                        line-height: 20px;
                        color: $grey;
                    }
                    .text{
                        margin-left: 10px;
                        line-height: 20px;
                        color: $text-color;
                        &.local{
                            margin-top: -5px;
                            line-height: 34px;
                        }
                    }
                }
            }
            .media-main{
                @extend .flex-row;
                a{
                    @extend .flex-column-center;
                    width: 36px;
                    height: 36px;
                    margin: 0 10px;
                    padding-top: 3px;
                    border-radius: $radius-circle;
                    background-color: #222;
                    &:hover{
                        opacity: .8;
                    }
                    &:first-child{
                        margin-left: 0;
                    }
                }
            }
        }
        .message-box{
            @extend .flex-column;
            position: relative;
            width: 40%;
            margin-left: 5%;
            .msg-form{
                @extend .flex-column;
                .item{
                    margin-bottom: 10px;
                    .input{
                        width: 100%;
                    }
                    &.doubel{
                        @extend .flex-row-between;
                        .input{
                            width: 48%;
                        }
                    }
                    &.captcha{
                        @extend .flex-row-between;
                        .input{
                            width: 70%;
                        }
                    }
                }
                .input{
                    line-height: 40px;
                    padding: 0 10px;
                    border-radius: 5px;
                    border: 1px solid #eee;
                    &.textarea{
                        height: 100px;
                        font-family: Arial, Helvetica, sans-serif;
                        resize: none;
                    }
                    &.error{
                        border-color: $danger;
                    }
                }
                .error{
                    padding: 0 10px;
                    font-size: 11px;
                    color: $danger;
                }
                .btn{
                    @extend .flex-row-center;
                    height: 40px;
                    border-radius: $radius;
                    border: none;
                    background-color: $orange;
                    font-size: 18px;
                    color: $white;
                    &.disabled{
                        cursor: not-allowed;
                    }
                    &:hover{
                        box-shadow: 0 0 10px rgba(0,0,0,.2);
                    }
                }
            }
            .mask-sended{
                @extend .flex-column-center;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(255,255,255, .7);
                font-size: 18px;
                font-family: 'cusTitle';
                color: $orange;
            }
        }
    }

    .footer-box{
        padding: 20px 0;
        text-align: center;
        background-color: #222;
        font-size: 12px;
        color: rgba(255,255,255, .5);
    }

    .modal-status{
        z-index: 999999;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0, .7);
        .modal-container{
            position: absolute;
            width: 300px;
            left: 50%;
            top: 40%;
            margin-left: -150px;
            padding: 20px;
            border-radius: 10px;
            text-align: center;
            background-color: $white;
            .icon{
                cursor: pointer;
                position: absolute;
                right: 10px;
                top: 10px;
            }
            h2{
                font-family: 'cusTitle';
                color: $orange;
            }
        }
    }
}
</style>