<template>
    <button class="n-button" 
        :class="[
            shape,
            type,
            size,
            loading ? 'loading' : ''
        ]"
        :disabled="disabled"
        @click="handlerClick"
    >
        <i class="n-icon prefix n-loading" v-if="loading && prefix === ''"></i>
        <i class="n-icon prefix" :class="prefix" v-if="prefix !== ''"></i>
        <span class="text">
            <slot></slot>
        </span>
        <i class="n-icon suffix" :class="suffix" v-if="suffix !== ''"></i>
    </button>
</template>

<script>
export default {
    name: 'NButton',
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        prefix: {
            type: String,
            default: ''
        },
        suffix: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        shape: {
            type: String,
            default: 'round'
        },
        size: {
            type: String,
            default: ''
        }
    },
    emits: ['click'],
    methods: {
        handlerClick(){
            if(!this.disabled && !this.loading) this.$emit('click')
        }
    }
}
</script>

<style lang="scss" scoped>
.n-button{
    @extend .flex-row-center;
    cursor: pointer;
    // width: 100%;
    height: $default-size;
    padding: 0 10px;
    border-radius: $radius;
    border: 1px solid $border-color;
    background-color: $white;
    .n-icon, .text{
        @extend .flex-column-middle;
        height: 100%;
    }

    // 图标
    .n-icon{
        font-size: 14px;
        &.suffix{
            margin-left: 10px;
        }
        &.prefix{
            margin-right: 10px;
        }
        // loading
        @keyframes rotate {
            0% {
                transform: rotate(0);
            }
            100% {
                transform: rotate(-360deg);
            }
        }
        &.n-loading{
            width: 14px;
            height: 14px;
            animation: rotate 2s linear infinite;
        }
    }

    &:hover{
        opacity: .8;
        border-color: $primary;
        background-color: $primary-light;
        color: $primary;
        box-shadow: 1px 1px 5px rgba(0,0,0,.05);
    }
    &:active{
        opacity: 1;
    }

    &[disabled]{
        opacity: .8;
        cursor: not-allowed;
    }

    &.loading{
        &:active, &{
            opacity: .8;
        }
    }

    // 形状
    &.round{
        border-radius: $radius-round;
    }
    &.circle{
        border-radius: $radius-circle;
        &.small{ 
            width: 24px; 
            padding: 0 3px;
        }
        &.large{ 
            width: 42px;
            padding: 0 5px; 
        }
    }

    // 尺寸
    &.small{
        height: 24px;
        padding: 0 8px;
        font-size: 11px;
    }
    &.large{
        height: 42px;
        padding: 0 15px;
    }

    // type
    &.primary{
        color: $white;
        border-color: $primary;
        background-color: $primary;
    }
    &.warning{
        color: $white;
        border-color: $warning;
        background-color: $warning;
    }
    &.success{
        color: $white;
        border-color: $success;
        background-color: $success;
    }
    &.danger{
        color: $white;
        border-color: $danger;
        background-color: $danger;
    }
}
</style>