import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'Home',
		meta: {
			title: 'Home',
			content: {
				keywords: '',
				description: ''
			}
		},
		component: () => import('@/views/Home.vue')
	},{
		path: '/equipment/:id',
		name: 'Equipment',
		meta: {
			title: 'Equipment',
			content: {
				keywords: '',
				description: ''
			}
		},
		component: () => import('@/views/Equipments.vue')
	},{
		path: '/equipment/detail/:id',
		name: 'Equipment Detail',
		meta: {
			title: 'Equipment Detail',
			content: {
				keywords: '',
				description: ''
			}
		},
		component: () => import('@/views/EquipmentDetail.vue')
	},{
		path: '/article/:id',
		name: 'Article',
		meta: {
			title: 'Article',
			content: {
				keywords: '',
				description: ''
			}
		},
		component: () => import('@/views/Articles.vue')
	},{
		path: '/article/detail/:id',
		name: 'Articles Detail',
		meta: {
			title: 'Articles Detail',
			content: {
				keywords: '',
				description: ''
			}
		},
		component: () => import('@/views/ArticlesDetail.vue')
	},{
		path: '/page/:id',
		name: 'Page',
		meta: {
			title: 'Page',
			content: {
				keywords: '',
				description: ''
			}
		},
		component: () => import('@/views/Page.vue')
	}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//     if (to.meta.title) {
//         document.title = `${to.meta.title} - Zhengzhou Xuanrun Machinery Equipment`;
//     }
// 	if(to.meta.content){
//         let head = document.getElementsByTagName('head');
//         let meta = document.createElement('meta');
//         document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
//         document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
//         meta.content = to.meta.content;
//         head[0].appendChild(meta)
//     }
//     next();
// });

export default router
