
import axios from 'axios';
import router from '@/router'

if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = 'http://xuanrun.test/api'
} else if (process.env.NODE_ENV === 'debug') {
    axios.defaults.baseURL = ''
} else if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = 'https://api.xrbrew.com/api'
}

//使用create方法创建axios实例
export const http = axios.create({
    timeout: 20000, // 请求超时时间
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})
// 添加请求拦截器
http.interceptors.request.use(config => {
    let accessToken = sessionStorage.getItem('accessToken');
    if (!accessToken) accessToken = localStorage.getItem('accessToken');
    config.headers['Authorization'] = accessToken;
    return config
}, function (error) {
    return Promise.reject(error)
})

// 添加响应拦截器
http.interceptors.response.use(res => {
    // console.log('响应拦截器：res ==', res)
    return res.data
}, err => {
    console.log('响应拦截器：err ==', err.response)
    if (err.response.status === 401) {
        sessionStorage.removeItem('accessToken');
        localStorage.removeItem('accessToken');
    }
    return Promise.reject(err.response)
})
