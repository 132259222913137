<template>
	<div class="app" ref="pageContainer">
		<n-header :scroll="isScroll" />
		<div class="app-container">
			<router-view />
		</div>
		<n-footer />
	</div>
</template>

<script>
import NHeader from '@/components/layout/Header.vue'
import NFooter from '@/components/layout/Footer.vue'
import { onMounted } from 'vue'
import router from '@/router'
export default {
	name: 'app',
	components: {
		NHeader, NFooter
	},
	data(){
		return {
			isScroll: false
		}
	},
	mounted(){
		document.addEventListener("scroll", this.pageScroll);
	},
	unmounted(){
		document.removeEventListener("scroll", this.pageScroll);
	},
	methods:{
		pageScroll(){
			// console.log(this.$refs)
			let top = document.documentElement.scrollTop;
			this.isScroll = top > 200 ? true : false;
		}
	}
}
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
html, body, #app{
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	background-color: #faf9ff;
}
@font-face {
	font-family: 'cusTitle';
	src: url('./assets/fonts/AvantGardeITCbyBT-Demi.ttf');
}
.cus-title{
	font-family: 'cusTitle';
	font-size: 42px;
	font-weight: bold;
}
.page-bg{
	z-index: 1;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 350px;
}
.page-bg .bg{
	width: 100%;
}

.breadcrumb{
	display: flex;
	flex-direction: row;
	color: #fff;
	font-size: 14px;
	font-weight: normal;
}
.breadcrumb a{
	color: #fff;
	text-transform:capitalize;
}
.breadcrumb a:hover{
	color: #DD5536;
}
</style>
