import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import api from '@/api/api'

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = `${to.meta.title} - Zhengzhou Xuanrun Machinery Equipment`;
    }
    if(to.meta.content){
        let head = document.getElementsByTagName('head');
        let meta = document.createElement('meta');
        // if(to.meta.content.keywords !== '')
            document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords);

        if(to.meta.content.description)
            document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description);
        meta.content = to.meta.content;
        head[0].appendChild(meta)
    }
    next();
})

const app = createApp(App)
app.config.globalProperties.$api = api
app.use(store).use(router).mount('#app')
